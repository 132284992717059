/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { SiteEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import { store } from '../../Models/Store';
import { Button, Display } from '../Components/Button/Button';
import axios from 'axios';
import { SERVER_URL } from '../../Constants';
import classNames from 'classnames';
import { getModelDisplayName } from '../../Util/EntityUtils';
import Spinner from '../Components/Spinner/Spinner';
// % protected region % [Add any extra imports here] end

export interface ViewSitesPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] on begin
class ViewSitesPage extends React.Component<ViewSitesPageProps, {
	siteQuantity: number,
	siteCount: number,
	loading: boolean
}> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	constructor(props: any, context: any) {
		super(props, context);
		this.state = {
			siteQuantity: 0,
			siteCount: 0,
			loading: true,
		};
	}

	// Fetch the organisation site quantity on mount
	async componentDidMount() {
		try {
			axios
				.get(`${SERVER_URL}/api/entity/OrganisationEntity/site-quantity/${store.userOrganisationId}`)
				.then(response => {
					const { data } = response;

					this.setState({
						siteQuantity: data.siteQuantity,
						siteCount: data.siteCount,
						loading: false,
					});
				});
		} catch (error) {
			// Unable to fetch organisation site data
			this.setState({ loading: false });
		}
	}

	// Fetch the organisation site quantity after update
	async componentDidUpdate() {
		try {
			axios
				.get(`${SERVER_URL}/api/entity/OrganisationEntity/site-quantity/${store.userOrganisationId}`)
				.then(response => {
					const { data } = response;

					const {
						siteQuantity,
						siteCount,
					} = this.state;

					// Ensure update only proceeds if the data has changed
					if (data.siteQuantity !== siteQuantity || data.siteCount !== siteCount) {
						this.setState({
							siteQuantity: data.siteQuantity,
							siteCount: data.siteCount,
							loading: false,
						});
					}
				});
		} catch (error) {
			// Unable to fetch organisation site data
			this.setState({ loading: false });
		}
	}
	// % protected region % [Add class properties here] end

	render() {
		const {
			match,
			location,
			history,
			staticContext,
			// % protected region % [Destructure any additional props here] off begin
			// % protected region % [Destructure any additional props here] end
		} = this.props;

		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		// eslint-disable-next-line prefer-const
		let contents = (
			// eslint-disable-next-line max-len
			<SecuredPage groups={['Super Administrators', 'Administrator', 'OrganisationAdmin']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<EntityCRUD
						{...this.props}
						modelType={SiteEntity}
						URLExtension="97a9789e-fb4f-4c67-8bc3-8987e5b334fa"
						// % protected region % [Add props to crud component 97a9789e-fb4f-4c67-8bc3-8987e5b334fa here] off begin
						// % protected region % [Add props to crud component 97a9789e-fb4f-4c67-8bc3-8987e5b334fa here] end
					/>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		const {
			siteQuantity,
			siteCount,
			loading,
		} = this.state;

		// Disable create button if site count is equal or greater than quantity
		let disableButton: boolean = false;
		if (siteCount >= siteQuantity) {
			disableButton = true;
		}

		// Recreate create button component with disabled
		// See EntityCollection.renderCreateButton function for replication
		const buttonComponent = () => {
			return (
				<Button
					key="create"
					className={classNames(Display.Solid)}
					icon={{ icon: 'create', iconPos: 'icon-right' }}
					buttonProps={{
						onClick: () => {
							history.push(`${match.url}/97a9789e-fb4f-4c67-8bc3-8987e5b334fa/create`);
						},
					}}
					disabled={disableButton}
				>
					Create {getModelDisplayName(SiteEntity)}
				</Button>
			);
		};

		contents = (
			<SecuredPage groups={['Super Administrators', 'Administrator', 'OrganisationAdmin']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				<div className="body-content">
					<h1 className="body-header">
						Organisation Sites
					</h1>
					<EntityCRUD
						{...this.props}
						collectionCreateAction={buttonComponent}
						modelType={SiteEntity}
						URLExtension="97a9789e-fb4f-4c67-8bc3-8987e5b334fa"
						actionsMore={[
							{
								customItem: <Button>View dashboard</Button>,
								onEntityClick: (event, entity) => store.routerHistory.push(`/dashboard/${entity.id}`),
							},
						]}
					/>
				</div>
			</SecuredPage>
		);

		// Show spinner if organisation site data has not loaded yet
		if (loading) {
			contents = <Spinner />;
		}
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default ViewSitesPage;
// % protected region % [Override export here] end
