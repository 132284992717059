/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Form, Question, Slide } from '../Schema/Question';
import { Button, Display } from 'Views/Components/Button/Button';
import { SlideTile } from '../SlideTile';
import { FormDesignerNewQuestion } from './FormDesignerNewQuestion';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { ContextMenu } from 'Views/Components/ContextMenu/ContextMenu';
import { contextMenu } from 'react-contexify';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { FormDesignerState } from 'Forms/Designer/FormSlideBuilder';
// % protected region % [Add any further imports here] on begin
import If from '../../Views/Components/If/If';
import { store } from '../../Models/Store';
import { canFormComponentRender } from '../FormUtils';
import { IContextMenuActions } from 'Views/Components/ContextMenu/ContextMenu';
// % protected region % [Add any further imports here] end

export interface FormDesignerContentsProps {
	schema: Form;
	model: Record<string, any>;
	designerState: FormDesignerState;
	// % protected region % [Add any further FormDesignerContentsProps here] off begin
	// % protected region % [Add any further FormDesignerContentsProps here] end
}

interface AfterSlideContentProps {
	slide: Slide;
	schema: Form;
	designerState: FormDesignerState;
	// % protected region % [Add any further AfterSlideContentProps here] off begin
	// % protected region % [Add any further AfterSlideContentProps here] end
}

// % protected region % [Customize AfterSlideContent here] on begin
function AfterSlideContent({ slide, schema, designerState }: AfterSlideContentProps) {
	return (
		<If condition={canFormComponentRender(
			store,
			false,
			false,
			false,
			[],
			slide,
		)}
		>
			<FormDesignerNewQuestion
				schema={schema}
				slide={slide}
				designerState={designerState}
			/>
		</If>
	);
}
// % protected region % [Customize AfterSlideContent here] end

@observer
export default class FormDesignerContents extends React.Component<FormDesignerContentsProps> {
	// % protected region % [Customize getQuestionOptions here] on begin
	private getQuestionOptions = (question: Question, slide: Slide, index: number) => {
		const contextMenuActions: IContextMenuActions = [
			{ label: 'Delete', onClick: () => this.confirmDeleteQuestion(question, slide, index) },
		];

		if (store.isSuperAdmin) {
			contextMenuActions.push(...[
				{ label: 'Move Question Up', onClick: () => this.moveQuestionUp(slide, index) },
				{ label: 'Move Question Down', onClick: () => this.moveQuestionDown(slide, index) },
			]);
		}

		return (
			<ButtonGroup>
				<If
					condition={
						canFormComponentRender(
							store,
							true,
							false,
							false,
							['Administrator'],
							slide,
						)
					}
				>
					<Button
						onClick={() => this.editQuestion(question, slide)}
						icon={{ icon: 'edit', iconPos: 'icon-left' }}
						display={Display.Outline}
					>
						Options
					</Button>
				</If>
				<If condition={canFormComponentRender(
					store,
					false,
					false,
					false,
					[],
					slide,
				)}
				>
					<Button
						icon={{ icon: 'more-horizontal', iconPos: 'icon-left' }}
						display={Display.Outline}
						labelVisible={false}
						onClick={event => contextMenu.show({ event: event, id: `forms-question-more-${question.id}` })}
						className="icon-only"
					>
						More
					</Button>
					<ContextMenu
						menuId={`forms-question-more-${question.id}`}
						actions={contextMenuActions}
					/>
				</If>
			</ButtonGroup>
		);
	};
	// % protected region % [Customize getQuestionOptions here] end

	// % protected region % [Customize moveQuestionUp here] off begin
	@action
	private moveQuestionUp = (slide: Slide, index: number) => {
		if (index > 0) {
			this.props.designerState.reset();
			const question = slide.contents.splice(index, 1);
			slide.contents.splice(index - 1, 0, ...question);
		}
	}
	// % protected region % [Customize moveQuestionUp here] end

	// % protected region % [Customize moveQuestionDown here] off begin
	@action
	private moveQuestionDown = (slide: Slide, index: number) => {
		if (index < slide.contents.length - 1) {
			this.props.designerState.reset();
			const question = slide.contents.splice(index, 1);
			slide.contents.splice(index + 1, 0, ...question);
		}
	}
	// % protected region % [Customize moveQuestionDown here] end

	// % protected region % [Customize confirmDeleteQuestion here] off begin
	private confirmDeleteQuestion = (question: Question, slide: Slide, index: number) => {
		confirmModal('Confirm', 'Do you want to delete this question')
			.then(() => this.deleteQuestion(question, slide, index));
	}
	// % protected region % [Customize confirmDeleteQuestion here] end

	// % protected region % [Customize deleteQuestion here] off begin
	@action
	private deleteQuestion = (question: Question, slide: Slide, index: number) => {
		if (this.props.designerState.selectedQuestion === question) {
			this.props.designerState.reset();
		}
		slide.contents.splice(index, 1);
	}
	// % protected region % [Customize deleteQuestion here] end

	// % protected region % [Customize editQuestion here] off begin
	@action
	private editQuestion = (question: Question, slide: Slide) => {
		this.props.designerState.mode = 'edit';
		this.props.designerState.selectedQuestion = question;
		this.props.designerState.selectedSlide = slide;
	}
	// % protected region % [Customize editQuestion here] end

	// % protected region % [Customize render here] on begin
	public render() {
		const { schema, model, designerState } = this.props;

		return (
			<section aria-label="form-builder" className="form-builder">
				{schema.slides.map((s, i) => {
					const key = `${s.name}-${i}`;
					return (
						<React.Fragment key={key}>
							<SlideTile
								model={model}
								schema={schema}
								name={s.name}
								contents={s.contents}
								disableShowConditions
								isReadOnly
								afterQuestionContent={this.getQuestionOptions}
								afterSlideContent={slide => (
									<AfterSlideContent
										slide={slide}
										designerState={designerState}
										schema={schema}
									/>
								)}
								isBaseFormSlide={s.isBaseFormSlide}
							/>
						</React.Fragment>
					);
				})}
			</section>
		);
	}
	// % protected region % [Customize render here] end

	// % protected region % [Add any further methods here] off begin
	// % protected region % [Add any further methods here] end
}
